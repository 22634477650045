import React from 'react';
import Head from 'next/head';
import cn from 'classnames';

import NavigationMenu from './NavigationMenu';
import FooterBar from './footerBar/footerBar';
import BannerHeader from '../slices/BannerHeader';
import { useGuide } from '../contexts/guide';
import TextureAndPerfumeGuide from './TextureAndPerfumeGuide';
import { ProductSnippet, ProductSnippetData } from '../utils/snippets';
import { useRouter } from 'next/router';

interface LayoutProps {
  children: any;
  bannerHeader?: any;
  menu: any;
  footer: any;
  title?: string;
  metaDescription?: string;
  logoH1?: boolean;
  isIndexed?: boolean;
  productSnippet?: ProductSnippetData;
}

const Layout = ({
  children,
  bannerHeader,
  menu,
  footer,
  title = 'Laboté | Cosmétique Botanique Sur-mesure',
  metaDescription = 'Trouvez votre routine de soins sur-mesure, fabriqués à partir de plantes médicinales.',
  logoH1 = false,
  isIndexed = true,
  productSnippet,
}: LayoutProps) => {
  const { displayGuide, setDisplayGuide } = useGuide();
  const router = useRouter();

  const canonicalUrl = process.env.NEXT_PUBLIC_SITE_URL + router.asPath;

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta name="google-site-verification"
              content="XM87lipvcYnNNfDJvN3Zfo_l9-msFX30zxgEHH2gkk0" 
        />
        <script
          type="module"
          src="https://cdn.scalapay.com/widget/v3/js/scalapay-widget.esm.js"
        ></script>
        <script
          noModule
          src="https://cdn.scalapay.com/widget/v3/js/scalapay-widget.js"
        ></script>
        <meta name="p:domain_verify" content="78b621a4ed8204ade44766a99e391578"/>
        {!isIndexed ? <meta name="robots" content="noindex" /> : null}
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <div className={cn({ 'display--none': !displayGuide })}>
        <TextureAndPerfumeGuide handleExit={() => setDisplayGuide(false)} />
      </div>
      <div
        style={{ overflowX: 'hidden' }}
        className={cn({ 'display--none': displayGuide })}
      >
        <BannerHeader slice={bannerHeader} />
        <NavigationMenu footer={footer} menu={menu} logoH1={logoH1} />
        <main className="wrapper">{children}</main>
        <FooterBar footer={footer} />
      </div>
      {!!productSnippet && ProductSnippet(productSnippet)}
    </div>
  );
};

export default Layout;
