import { Client } from '../prismic-configuration';
import { useGetStaticProps } from 'next-slicezone/hooks';
import SliceZone from 'next-slicezone';
import resolver from '../sm-resolver.js';
import Layout from '../components/Layout';
import api from '../services/api';
import { useEffect } from 'react';
import { useOrder } from '../contexts/order';
import { useRouter } from 'next/router';
import {
  payload1,
  product1,
  product2,
  payload2,
  product3,
  payload3,
} from '../utils/constant';

const PAGE = 1;
const PAGE_SIZE = 30;

const Page = (props) => {
  const { addOrderItem } = useOrder();
  const router = useRouter();

  const addProductFromSocialShopping = (l: string[]) => {
    for (const n of l) {
      const [id, nbr] = n.split(':');
      if (id === '1')
        setTimeout(() => {
          // @ts-ignore
          addOrderItem(product1, 'product_page', {
            ...payload1,
            quantity: parseInt(nbr),
          });
        });
      if (id === '2')
        setTimeout(() => {
          // @ts-ignore
          addOrderItem(product2, 'product_page', {
            ...payload2,
            quantity: parseInt(nbr),
          });
        }, 500);
      if (id === '3')
        setTimeout(() => {
          // @ts-ignore
          addOrderItem(product3, 'product_page', {
            ...payload3,
            quantity: parseInt(nbr),
          });
        }, 1000);

      setTimeout(() => {
        router.push('/paiement/?promocode=SHOP20');
      }, 1500);
    }
  };

  useEffect(() => {
    const queryProducts = router.query.queryProducts;
    const products = router.query.products;
    if (queryProducts && typeof queryProducts === 'string')
      setTimeout(() => {
        router.push('/?' + `products=${queryProducts}`);
      }, 2000);
    if (products && typeof products === 'string')
      addProductFromSocialShopping(products.split(','));
  }, [router.query]);

  return (
    <Layout
      bannerHeader={props.bannerHeader}
      menu={props.menu}
      footer={props.footer}
      logoH1
    >
      <SliceZone {...props} resolver={resolver} />
    </Layout>
  );
};

export async function getStaticProps(context) {
  const prismicProps = await useGetStaticProps({
    client: Client(),
    apiParams({ params }) {
      return {
        lang: params.lang,
        uid: 'home',
        fetchLinks: [
          'blogpost.uid',
          'blogpost.meta_title',
          'blogpost.publishDate',
          'blogpost.social_cards',
          'product.product',
          'accessory.accessory',
          'product_page.product_page',
        ],
      };
    },
  })(context);

  const { data: reviewData } = await api.get(
    `api/reviews/?page=${PAGE}&page_size=${PAGE_SIZE}`,
  );

  prismicProps.props.slices = prismicProps.props.slices.map((s) => {
    if (s.slice_type === 'home_review') {
      s.primary.reviewData = reviewData.results;
    }
    return s;
  });

  return prismicProps;
}

export default Page;
