import React from 'react';
import { ProductJsonLd } from 'next-seo';

export interface ProductSnippetData {
  name: string;
  description: string;
  image: string;
  price: string;
  ratingValue: string;
  ratingCount: string;
}

export const ProductSnippet = ({
  name,
  description,
  image,
  price,
  ratingValue,
  ratingCount,
}: ProductSnippetData) => (
  <ProductJsonLd
    productName={name}
    images={[image]}
    description={description}
    aggregateRating={{ ratingValue, ratingCount }}
    offers={[{ price, priceCurrency: 'EUR', seller: { name: 'Labote' } }]}
  />
);
